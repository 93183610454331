export const WHATSAPP_NUMBER = '60172922890';
export const WHATSAPP_API_SEND = 'https://api.whatsapp.com/send';

export const BACKEND_URL = '/submit';
export const COLUMNS = {
    PACKAGE_NAME: 'Nama Pakej',
    NAME: 'Nama',
    PHONE: 'No. Telefon',
    EMAIL: 'Email',
    PAX: 'Jumlah Pax',
    PACKAGE_TYPE: 'Jenis Pakej',
    START_DATE: 'Tarikh Anggaran',
    TRAVEL_MONTH: 'Jangkaan Travel Bulan Berapa',
    OTHERS: 'Lain-lain Permintaan',
};

export const SPECIAL_ID = {
    x: 'x',
    a: 'a',
    b: 'b',
    c: 'c',
    d: 'd',
};

export const SPECIAL_IDS = [
    SPECIAL_ID.x,
    SPECIAL_ID.a,
    SPECIAL_ID.b,
    SPECIAL_ID.c,
    SPECIAL_ID.d,
];

export const PACKAGE_TYPES = [
    { value: 'Private Trip', label: 'Private Trip' },
    { value: 'Join/Mix Group', label: 'Join/Mix Group' },
];

export const MONTHS = [
    { value: 'jan', label: 'Januari' },
    { value: 'feb', label: 'Febuari' },
    { value: 'mar', label: 'March' },
    { value: 'apr', label: 'April' },
    { value: 'may', label: 'Mei' },
    { value: 'jun', label: 'Jun' },
    { value: 'jul', label: 'July' },
    { value: 'aug', label: 'Ogos' },
    { value: 'sep', label: 'September' },
    { value: 'oct', label: 'Oktober' },
    { value: 'nov', label: 'November' },
    { value: 'dec', label: 'Disember' },
];

export const PANGGILAN = [
    { value: 'Encik', label: 'Encik' },
    { value: 'Puan', label: 'Puan' },
    { value: 'Cik', label: 'Cik' },
    { value: 'Haji', label: 'Haji' },
    { value: 'Hajah', label: 'Hajah' },
    { value: 'Dr', label: 'Dr' },
    { value: 'Prof', label: 'Prof' },
    { value: 'Dato', label: 'Dato' },
    { value: 'Datin', label: 'Datin' },
    { value: 'Tan Sri', label: 'Tan Sri' },
];

export const PACKAGES = [
    { id: 1, name: 'Bandung-Jakarta 4H3M H/B', set: [SPECIAL_ID.x, SPECIAL_ID.a, SPECIAL_ID.c] },
    { id: 2, name: 'Bandung-Jakarta Speed Train 4H3M H/B', set: [SPECIAL_ID.x, SPECIAL_ID.a] },
    { id: 3, name: 'Bandung-Jakarta 4H3M F/B', set: [SPECIAL_ID.x] },
    { id: 4, name: 'Bali 4H3M H/B', set: [SPECIAL_ID.x, SPECIAL_ID.a] },
    { id: 5, name: 'Jogjakarta 4H3M H/B', set: [SPECIAL_ID.x, SPECIAL_ID.a] },
    { id: 6, name: 'Padang Bukit Tinggi 4H3M F/B', set: [SPECIAL_ID.x, SPECIAL_ID.b] },
    { id: 7, name: 'KK-Kundasang 3H2M H/B', set: [SPECIAL_ID.x, SPECIAL_ID.d] },
    { id: 8, name: 'Medan-Lake Toba 4H3M F/B', set: [SPECIAL_ID.x, SPECIAL_ID.b] },
    { id: 9, name: 'Banda Aceh 4H3M F/B', set: [SPECIAL_ID.x, SPECIAL_ID.b] },
    { id: 10, name: 'Bromo-Surabaya 4H3M F/B', set: [SPECIAL_ID.x, SPECIAL_ID.a] },
    { id: 11, name: 'Dalat Mui Ne-HCM 5H4M F/B', set: [SPECIAL_ID.x, SPECIAL_ID.b, SPECIAL_ID.c] },
    { id: 12, name: 'Hanoi Sapa 4H3M F/B', set: [SPECIAL_ID.x, SPECIAL_ID.c] },
    { id: 13, name: 'HCM-Mekong 4H3M F/B', set: [SPECIAL_ID.x, SPECIAL_ID.c] },
    { id: 14, name: 'Hatyai By Van From KL', set: [SPECIAL_ID.x, SPECIAL_ID.d] },
    { id: 15, name: 'Hatyai By bas From KL', set: [SPECIAL_ID.x, SPECIAL_ID.d] },
    { id: 16, name: 'Bandung Direct 4H3M H/B', set: [SPECIAL_ID.x, SPECIAL_ID.a] },
    { id: 17, name: 'Perhentian 3H2M F/B', set: [SPECIAL_ID.x, SPECIAL_ID.d] },
];