import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';

import './Main.css';
import * as Constants from '../utils/constants';
import * as Func from '../utils/func';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Main() {
    const nameRef = useRef();
    const phoneRef = useRef();
    const travelMonthRef = useRef();

    const query = useQuery();
    const packageId = query.get('package_id') || 1;
    const whatsappNumber = query.get('whatsapp_no') || Constants.WHATSAPP_NUMBER;

    const [packageName, setPackageName] = useState(Constants.PACKAGES[packageId - 1]?.name || Constants.PACKAGES.filter(p => p.set.includes(packageId))?.[0]?.name || Constants.PACKAGES[0]?.name);
    const [salutation, setSalutation] = useState('Encik');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [pax, setPax] = useState(1);
    const [packageType, setPackageType] = useState('Private Trip');
    const [startDate, setStartDate] = useState('');
    const [travelMonth, setTravelMonth] = useState('');
    const [others, setOthers] = useState('');

    const goToWhatsapp = () => {
        if (name !== '' && travelMonth !== '' && phone !== '') {
            const startDateStr = startDate !== '' ? `*${Func.getDate(startDate)}*` : '';
            const emailStr = email !== '' ? `*${email}*` : '';
            const othersStr = others !== '' ? `*${others}*` : '';
            const text = `Hi, boleh saya request quotation seperti di bawah?\n\nPakej: *${packageName}*\n\nNama Panggilan: *${salutation} ${name?.toUpperCase()}*\nNo. Telefon: *${phone}*\nEmail: ${emailStr}\nJumlah Pax: *${pax}*\n\nJenis Pakej: *${packageType}*\nTarikh Anggaran Jika Ada: ${startDateStr}\nJangkaan Travel Bulan Berapa?: *${travelMonth}*\nLain2 Permintaan: ${othersStr}`;
            window.open(`${Constants.WHATSAPP_API_SEND}?phone=${whatsappNumber}&text=${encodeURIComponent(text)}`, '_blank').focus();
            axios.post(Constants.BACKEND_URL, {
                data: Object.values({
                    [Constants.COLUMNS.PACKAGE_NAME]: packageName,
                    [Constants.COLUMNS.NAME]: `${salutation} ${name?.toUpperCase()}`,
                    [Constants.COLUMNS.PHONE]: phone,
                    [Constants.COLUMNS.EMAIL]: email,
                    [Constants.COLUMNS.PAX]: pax,
                    [Constants.COLUMNS.PACKAGE_TYPE]: packageType,
                    [Constants.COLUMNS.START_DATE]: startDate,
                    [Constants.COLUMNS.TRAVEL_MONTH]: travelMonth,
                    [Constants.COLUMNS.OTHERS]: others,
                }),
                sheetName: packageName,
                columns: Object.values(Constants.COLUMNS),
            });
        } else {
            toast.error("Jangan biarkan kosong untuk Nama, No. Telefon, dan Jangkaan Travel Bulan!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            if (name === '') {
                nameRef.current.focus();
            } else if (phone === '') {
                phoneRef.current.focus();
            } else if (travelMonth === '') {
                travelMonthRef.current.focus();
            }
        }
    };

    // eslint-disable-next-line
    if (!Constants.PACKAGES.find(p => p.id == packageId) && !Constants.SPECIAL_IDS.includes(packageId)) {
        return (
            <h2 style={{ textAlign: 'center' }}>Please choose a valid package!</h2>
        );
    }

    return (
        <div className='row main-container'>
            <ToastContainer />
            <div className='col-md-6 offset-md-3 main-form'>
                <div className='card main-card'>
                    <strong className='main-title'>
                        <img src='./logoText.png' alt='Logo Text' /><br />
                        ISI DETAIL UNTUK DIHANTAR KE WHATSAPP
                    </strong>
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td colSpan={3} className='main-package-name'>
                                    <strong>Pakej {packageName}</strong>
                                </td>
                            </tr>
                            {Constants.SPECIAL_IDS.includes(packageId) && <tr>
                                <td width='35%'>Pakej <span className='main-alert'>*</span></td>
                                <td>:</td>
                                <td>
                                    <select name='package-name' className='form-select' onChange={evt => setPackageName(evt.target.value)} value={packageName}>
                                        {Constants.PACKAGES.filter(p => p.set.includes(packageId)).map(p => (
                                            <option value={p.name} key={p.name}>{p.name}</option>
                                        ))}
                                    </select>
                                </td>
                            </tr>}
                            <tr>
                                <td width='35%'>Nama Panggilan <span className='main-alert'>*</span></td>
                                <td>:</td>
                                <td className='main-name-container'>
                                    <select name='salutation' className='form-select main-salutation' onChange={evt => setSalutation(evt.target.value)} value={salutation}>
                                        {Constants.PANGGILAN.map(p => (
                                            <option value={p.value} key={p.value}>{p.label}</option>
                                        ))}
                                    </select>
                                    <input type="text" name="name" className='form-control' placeholder='Isi nama panggilan...' value={name} onChange={evt => setName(evt.target.value)} ref={nameRef} />
                                </td>
                            </tr>
                            <tr>
                                <td>No. Telefon <span className='main-alert'>*</span></td>
                                <td>:</td>
                                <td>
                                    <input type="text" name="phone" className='form-control' placeholder='Isi no. telefon...' value={phone} onChange={evt => setPhone(evt.target.value)} ref={phoneRef} />
                                </td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>:</td>
                                <td>
                                    <input type="text" name="email" className='form-control' placeholder='Eg.: umaq3@gmail.com' value={email} onChange={evt => setEmail(evt.target.value)} />
                                </td>
                            </tr>
                            <tr>
                                <td>Jumlah Pax <span className='main-alert'>*</span></td>
                                <td>:</td>
                                <td>
                                    <input type="number" name="pax" className='form-control' placeholder='Isi jumlah pax...' value={pax} onChange={evt => setPax(evt.target.value)} />
                                </td>
                            </tr>
                            <tr>
                                <td>Jenis Pakej <span className='main-alert'>*</span></td>
                                <td>:</td>
                                <td>
                                    <select name='package_type' className='form-select' value={packageType} onChange={evt => setPackageType(evt.target.value)}>
                                        {Constants.PACKAGE_TYPES.map(pt => (
                                            <option value={pt.value} key={pt.value}>{pt.label}</option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Tarikh Anggaran (Jika ada)</td>
                                <td>:</td>
                                <td>
                                    <input type="date" name="start_date" className='form-control' placeholder='Pilih tarikh pergi...' value={startDate} onChange={evt => setStartDate(evt.target.value)} />
                                </td>
                            </tr>
                            <tr>
                                <td>Jangkaan Travel Bulan Berapa <span className='main-alert'>*</span></td>
                                <td>:</td>
                                <td>
                                    <input type="text" name="travel_month" className='form-control' placeholder='Eg. Awal Julai.' value={travelMonth} onChange={evt => setTravelMonth(evt.target.value)} ref={travelMonthRef} />
                                </td>
                            </tr>
                            <tr>
                                <td>Lain-lain Permintaan</td>
                                <td>:</td>
                                <td>
                                    <textarea name='others' className='form-control' placeholder='Eg.: Nak bagasi 20KG.' onChange={evt => setOthers(evt.target.value)} value={others}>{others}</textarea>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={3} className='main-button-container'>
                                    <button className='btn btn-success main-button-send' onClick={goToWhatsapp}>HANTAR WHATSAPP</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Main;
